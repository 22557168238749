<template>
  <div class="flex mt-40">
    <div class="w50">
      <ResultItem
        v-if="prevalence && prevalence.gam && prevalence.gam.length > 0"
        :title="$t('components.titles.prevalenceOfGlobalAcuteMalnutrition')"
        :subtext="globalAcuteSubtext"
        :prevalence="prevalence.gam"
        :survey-info="surveyInfo"/>
      <a-empty
        v-if="!prevalence || prevalence.gam && prevalence.gam.length === 0"
        :description="$t('components.description.notEnoughDataToDetermineGlobalAcuteMalnutrition')"/>
      <ResultItem
        v-if="prevalence && prevalence.mam && prevalence.mam.length > 0"
        class="mt-40"
        :title="$t('components.titles.prevalenceOfModerateAcuteMalnutrition')"
        :subtext="moderateAcuteSubtext"
        :prevalence="prevalence.mam"
        :survey-info="surveyInfo"/>
      <a-empty
        v-if="!prevalence || prevalence.mam && prevalence.mam.length === 0"
        class="mt-40"
        :description="$t('components.description.notEnoughDataToDetermineModerateAcuteMalnutrition')"/>
    </div>
    <div class="w50">
      <ResultItem
        v-if="prevalence && prevalence.sam && prevalence.sam.length > 0"
        :title="$t('components.titles.prevalenceOfSevereAcuteMalnutrition')"
        :subtext="severeAcuteSubtext"
        :prevalence="prevalence.sam"
        :survey-info="surveyInfo"/>
      <a-empty
        v-if="!prevalence || prevalence.sam && prevalence.sam.length === 0"
        :description="$t('components.description.notEnoughDataToDetermineSevereAcuteMalnutrition')"/>
      <ResultSummary
        v-if="prevalence && prevalence.footer"
        :summary="prevalence.footer"
        :survey-info="surveyInfo"
        :type="type"
        class="mt-20"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnthropometryResultsPrevalanceMUAC',
  components: {
    ResultItem: () => import('../result-item'),
    ResultSummary: () => import('../result-summary')
  },
  props: {
    type: {
      type: String,
      default: undefined
    },
    prevalence: {
      type: Object,
      default: undefined
    },
    surveyInfo: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    globalAcuteSubtext() {
      if (this.surveyInfo && this.surveyInfo.settings && this.surveyInfo.settings.muac_upr != null) {
        return this.$t('components.labels.muacOrEdema', {
          symbol: '<',
          value: this.surveyInfo.settings.muac_upr,
          metric: this.$t('values.mm')
        });
      }
      return '';
    },
    moderateAcuteSubtext() {
      if (this.surveyInfo && this.surveyInfo.settings && this.surveyInfo.settings.muac_upr != null && this.surveyInfo.settings.muac_lwr != null) {
        return this.$t('components.labels.muacGreaterAndLessThan', {
          symbol: '<',
          value: this.surveyInfo.settings.muac_upr,
          metric: this.$t('values.mm'),
          muac: this.$t('components.titles.muacGreaterOrLessThan', {
            symbol: '>=',
            value: this.surveyInfo.settings.muac_lwr,
            metric: this.$t('values.mm')
          })
        });
      }
      return '';
    },
    severeAcuteSubtext() {
      if (this.surveyInfo && this.surveyInfo.settings && this.surveyInfo.settings.muac_lwr != null) {
        return this.$t('components.labels.muacOrEdema', {
          symbol: '<',
          value: this.surveyInfo.settings.muac_lwr,
          metric: this.$t('values.mm')
        });
      }
      return '';
    }
  }
};
</script>

<style lang="scss">

</style>
